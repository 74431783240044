import * as React from "react";
import { useState, useEffect } from "react";
import * as style from "./typeWritter.module.scss";

const TypeWriter = ({ content = "", speed = 1000, className }) => {
  const [displayedContent, setDisplayedContent] = useState("");
  const [index, setIndex] = useState(0);
  useEffect(() => {
    const animKey = setInterval(() => {
      setIndex((index) => {
        if (index >= content.length - 1) {
          clearInterval(animKey);
          return index;
        }
        return index + 1;
      });
    }, speed);
  }, [content, speed]);

  useEffect(() => {
    setDisplayedContent(
      (displayedContent) => displayedContent + content[index]
    );
  }, [index, content]);

  return <pre className={className}>{displayedContent}</pre>;
};

TypeWriter.propTypes = {};

TypeWriter.defaultProps = {
  className: style.TypeWriter,
};

export default TypeWriter;
