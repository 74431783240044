import * as React from "react";
import * as style from "./styles/home.module.scss";
import { graphql } from "gatsby";
import { motion } from "framer-motion";
import { useTranslation, Trans, Link } from "gatsby-plugin-react-i18next";
import { getImage } from "gatsby-plugin-image";
import uuid from "react-uuid";
import { useMediaQuery } from "react-responsive";
import Seo from "components/Seo";
import { mobileBreakpoint } from "components/Mediaqueries";
import TypeWriter from "components/TypeWritter";
import ScrollIndicator from "components/ScrollIndicator";
import BoundedSection from "components/BoundedSection";
import Parallax from "components/Parallax";
import MorphingScrollImg from "components/MorphingScrollImg";
import CursorProvider from "components/CursorProvider";
import IntroVideoMp4 from "../images/video.mp4";
import IntroVideoWebm from "../images/video.webm";

const headerVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const Header = ({ t }) => (
  <BoundedSection
    className={style.Header}
    boundedClassName={style.Header_content}
  >
    <div className={style.TextWrapper}>
      <motion.div
        className={style.PreTitle}
        variants={headerVariants}
        initial="initial"
        animate="animate"
      >
        <TypeWriter content={t("home.header.preTitle")} speed={100} />
      </motion.div>
      <motion.h1
        className={style.Title}
        variants={headerVariants}
        initial="initial"
        animate="animate"
      >
        <Trans i18nKey="home.header.title" />
      </motion.h1>
      <motion.h2
        className={style.SubTitle}
        variants={headerVariants}
        initial="initial"
        animate="animate"
      >
        <Trans i18nKey="home.header.subTitle" />
      </motion.h2>
    </div>
    <Parallax distance={0.1} className={style.GraphicParallax}>
      <motion.div
        className={style.GraphicWrapper}
        variants={headerVariants}
        initial="initial"
        animate="animate"
      >
        <video autoPlay loop muted playsInline>
          <source src={IntroVideoWebm} type="video/webm" />
          <source src={IntroVideoMp4} type="video/mp4" />
        </video>
      </motion.div>
    </Parallax>
    <ScrollIndicator />
  </BoundedSection>
);

const Bubble = ({ title, link, img, size, parallaxDistance }) => {
  return (
    <Parallax distance={parallaxDistance}>
      <Link
        to={link}
        className={`${style.Bubble} ${size}`}
        data-cursor-size="144px"
        data-cursor-color="#263546"
        data-cursor-text={title}
      >
        <MorphingScrollImg img={img} alt={title} className={style.Bubble_img} />
      </Link>
    </Parallax>
  );
};

const bubblesData = [
  {
    title: "nav.services",
    link: "/services",
    img: (data) => data && getImage(data.servicesImg),
    size: style.l,
    parallaxDistance: 0.1,
  },
  {
    title: "nav.about",
    link: "/about",
    img: (data) => data && getImage(data.aboutImg),
    size: style.m,
    parallaxDistance: 0.2,
  },
  {
    title: "nav.contact",
    link: "/contact",
    img: (data) => data && getImage(data.contactImg),
    size: style.s,
    parallaxDistance: 0.2,
  },
  {
    title: "nav.joinUs",
    link: "/join-us",
    img: (data) => data && getImage(data.joinUsImg),
    size: style.l,
    parallaxDistance: -0.085,
  },
  {
    title: "nav.methodology",
    link: "/methodology",
    img: (data) => data && getImage(data.methodologyImg),
    size: style.m,
    parallaxDistance: -0.2,
  },
];

const Bubbles = ({ t, data }) => {
  const isMobile = useMediaQuery(mobileBreakpoint);
  return (
    <BoundedSection
      className={style.Bubbles}
      boundedClassName={style.Bubbles_grid}
    >
      {bubblesData.map(({ title, link, img, size, parallaxDistance }) => (
        <Bubble
          key={uuid()}
          title={t(title)}
          link={link}
          img={img(data)}
          size={size}
          parallaxDistance={isMobile ? "0.1" : parallaxDistance}
        />
      ))}
    </BoundedSection>
  );
};

const IndexPage = ({ data }) => {
  console.log(
    "%cCurious, huh? 🙃",
    "color: lighten(#081832, 6%); font-size:32px; font-weight: bold;"
  );
  const { t } = useTranslation();
  return (
    <main>
      <CursorProvider />
      <Seo
        title={t("home.seo.title")}
        description={t("home.seo.description")}
      />
      <Header t={t} />
      <Bubbles t={t} data={data} />
    </main>
  );
};

export default IndexPage;

export const data = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    servicesImg: file(relativePath: { eq: "nav/services.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    aboutImg: file(relativePath: { eq: "nav/about.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    contactImg: file(relativePath: { eq: "nav/contact.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    joinUsImg: file(relativePath: { eq: "nav/joinus.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    methodologyImg: file(relativePath: { eq: "nav/methodology.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
  }
`;
