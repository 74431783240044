// extracted by mini-css-extract-plugin
export var Bubble = "home-module--Bubble--NOvqV";
export var Bubble_img = "home-module--Bubble_img--x6svv";
export var Bubbles = "home-module--Bubbles--hr0sL";
export var Bubbles_grid = "home-module--Bubbles_grid--w6-Uh";
export var GraphicParallax = "home-module--GraphicParallax--2gfqo";
export var GraphicWrapper = "home-module--GraphicWrapper--eBfk6";
export var Header = "home-module--Header--JuQUs";
export var Header_content = "home-module--Header_content--YUk8b";
export var PreTitle = "home-module--PreTitle--Ahi5Y";
export var SubTitle = "home-module--SubTitle--YJeIA";
export var TextWrapper = "home-module--TextWrapper--19D+x";
export var Title = "home-module--Title--La8U6";
export var l = "home-module--l--MnR0i";
export var m = "home-module--m--k8DMO";
export var s = "home-module--s--ARCIJ";