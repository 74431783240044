import * as React from "react";
import { useState, useEffect } from "react";
import * as style from "./scrollIndicator.module.scss";
import { motion, AnimatePresence } from "framer-motion";

const ScrollIndicator = ({ className }) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    window.addEventListener("scroll", updatePosition);
    return () => window.removeEventListener("scroll", updatePosition);
  }, []);

  return (
    <>
      <AnimatePresence>
        {scrollPosition < 200 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className={style.ScrollIndicator}
          >
            <div className={style.ScrollIndicator_mouseIcon}>
              <span className={style.ScrollIndicator_mouseWheel}></span>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default ScrollIndicator;
